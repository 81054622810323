
import {Component, Prop} from 'vue-property-decorator';
import Vue from "vue";

@Component
export default class GoogleSSOButtonComponent extends Vue {
  @Prop({required: true})
  private onGoogleLoginCompleted!: (idToken?: string) => void;

  private onLoad() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id : this.googleClientId,
      callback: this.googleCompleted
    });

    const element = document.getElementById('google_sso_button');
    // @ts-ignore
    google.accounts.id.renderButton(
        element,
        {
          'shape': "rectangular",
          'theme': "filled_black",
          'text': "continue_with",
          "size": "large",
          "logo_alignment": "left"
        }
    );
  }

  private async loadScript() {
    const script = document.createElement( "script" );
    script.type = "text/javascript";

    script.onload = () => {
      this.onLoad();
    };

    script.src = 'https://accounts.google.com/gsi/client';
    document.getElementsByTagName( "head" )[0].appendChild( script );
  }

  private async created() {
    await this.loadScript();
  }

  private get googleClientId(): string {
    return process.env.VUE_APP_GOOGLE_CLIENT_ID!;
  }

  private googleCompleted(value: any) {
    this.onGoogleLoginCompleted(value['credential']);
  }
}
